<script>
import Detail from './detail'
import DatePicker from "@vuepic/vue-datepicker"
import PartnerBadge from '../badge-level'
// import PartnerTreeMenu from '@/components/partner/tree-menu'
import BadgeSeamless from "@/components/badge-seamless"
import BadgeTransactionType from "@/components/badge-transaction-type"
import BadgeTransactionCategory from "@/components/badge-transaction-category"
import BadgeGameCategory from "@/components/badge-game-category"
import SelectVendors from "@/components/select-vendors"
// import SelectGames from "@/components/select-games"

export default {
  props: {
    searchByUser: Boolean,
    searchByParent: Boolean,
    user: Object,
    parent: Object
  },
  components: {
    Detail,
    DatePicker,
    PartnerBadge,
    BadgeSeamless,
    BadgeTransactionType,
    BadgeTransactionCategory,
    BadgeGameCategory,
    SelectVendors,
    // SelectGames
  },
  data() {
    return {
      search: {
        groupKey: '',
        searchType: 'username',
        searchText: '',
        method: '',
        category: '',
        type: '',
        vendor: '',
        game: '',
        seamlessState: '',
        beginDate: '',
        endDate: '',
        user: null
      },
      sort: { createdAt: -1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1, // 현재 페이지
      includeBelow: false,

      games: []
    }
  },
  created() {

  },
  mounted() {

    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 1)
    beginDate.setHours(0)
    beginDate.setMinutes(0)
    beginDate.setSeconds(0)

    // let endDate = new Date()

    this.search.beginDate = beginDate
    // this.search.endDate = endDate

    const a = localStorage.getItem('limit')
    this.limit = (isNaN(a) || !a) ? 50 : parseInt(a)

    this.search.parent = this.parent ? this.parent.id : null

    if (this.searchByUser === false && this.searchByParent === false) {
      this.searchList().then()
    } else {
      //this.$refs.partnerTreeMenu.getPartners().then()
      this.searchList().then()
    }

  },
  methods: {
    setParent: function(id) {
      this.search.parent = id
    },
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      // this.searchList().then()
    },
    goFirst() {
      this.page = 1
    },
    goPre() {
      if (this.page === 1) {
        this.page = 1
      } else {
        this.page -= 1
      }
    },
    goNext() {
      this.page += 1
    },
    searchByPage: function(page) {
      this.page = page
    },
    searchList: async function() {
      if (this.searchByUser === true && !this.user) return
      if (this.searchByParent === true && !this.parent) return

      const loader = this.$loading.show({container: this.$refs.contentList})
      try {
        localStorage.setItem('limit', this.limit)
        const params = {
          search: { includeBelow: this.includeBelow, method: this.search.method, category: this.search.category, type: this.search.type, vendor: this.search.vendor, game: this.search.game, seamlessState: this.search.seamlessState, user: this.user ? this.user.id : null, parent: this.search.parent },
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText

        const result = await this.$API.transaction.list(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        for(let key in result.documents) {
          let doc = result.documents[key]
          doc.showDetail = false
          this.list.push(doc)
        }

        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    openDetail: function(data) {
      this.$refs['modal-detail'].open(data)
    },

    searchVendors(id) {
      this.search.vendor = id
      // this.$refs.refSearchGames.getGames(id)
    },
    selectGames(id) {
      this.search.game = id
    }
  },
  computed: {

  },
  watch: {
    page: function() {
      this.searchList().then()
    },
    user: function(val) {
      if (val) {
        this.searchList().then()
      }
    },
    parent: function(val) {
      if (val) {
        // console.log('***', val)
        this.searchList().then()
      }
    }
  }
}
</script>

<template>
  <div>
    <form class="header-search" @submit.prevent="searchList">

      <!-- 검색 //-->
      <div class="row">

        <div class="form-group col-md-2 mb-2" v-if="searchByUser === false && searchByParent === false">
        </div>

        <div class="form-group col-md-2 mb-2">
          <select class="form-select" v-model="search.category">
            <option value="">{{ $t('거래형식') }}</option>
            <option value="">---------------------</option>
            <option value="cash">{{ $t('파트너') }} {{ $t('캐쉬') }}</option>
            <option value="money">{{ $t('회원') }} {{ $t('캐쉬') }}</option>
            <option value="turn">{{ $t('게임') }}</option>
          </select>
        </div>

        <div class="form-group col-md-2 mb-2">
          <select class="form-select" v-model="search.type">
            <option value="">{{ $t('거래방식') }}</option>
            <option value="">---------------------</option>
            <option value="bet">{{ $t('배팅') }}</option>
            <option value="win">{{ $t('승') }}</option>
            <option value="draw">{{ $t('무') }}</option>
            <option value="lose">{{ $t('패') }}</option>
            <option value="cancel">{{ $t('취소') }}</option>
            <option value="">---------------------</option>
            <option value="receive">{{ $t('입금') }}</option>
            <option value="send">{{ $t('출금') }}</option>
          </select>
        </div>

        <div class="form-group col-md-2 mb-2">
          <select-vendors @searchVendors="searchVendors"></select-vendors>
        </div>

        <!--
        <div class="form-group col-md-2 mb-2">
          <select-games @selectGames="selectGames" ref="refSearchGames"></select-games>
        </div>
        //-->

        <div class="form-group col-md-2 mb-2">
          <select class="form-select" v-model="search.searchType" v-if="!user">
            <option value="username">{{ $t('아이디') }}</option>
            <option value="siteUsername">{{ $t('사이트 아이디') }}</option>
            <option value="transactionKey">{{ $t('거래코드') }}</option>
          </select>
        </div>

        <div class="form-group col-md-2 mb-2" v-if="!user">
          <input type="text" class="form-control" v-model="search.searchText" :placeholder="$t('검색어')" />
        </div>
      </div>

      <!-- 페이징 및 검색 //-->
      <div class="row">
        <div class="col-md-2" v-if="searchByUser === false && searchByParent === false">
        </div>
        <div class="col-md-2">
          <div class="form-group mb-2">
            <DatePicker
                v-model="search.beginDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :close-on-auto-apply="false"
                :placeholder="$t('시작일')"
            ></DatePicker>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group mb-2">
            <DatePicker
                v-model="search.endDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :placeholder="$t('종료일')"
            ></DatePicker>
          </div>
        </div>
        <div class="col-md-2 mb-2">
          <select class="form-select" v-model="limit">
            <option :value="10">10개</option>
            <option :value="25">25개</option>
            <option :value="50">50개</option>
            <option :value="100">100개</option>
          </select>
        </div>

        <div class="form-group col-md-2 mb-2" v-if="searchByUser === true || searchByParent === true">
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="input-group mb-2">
              <button type="button" class="btn btn-outline-success" @click="goFirst">처음</button>
              <button type="button" class="btn btn-outline-primary" @click="goPre">이전</button>
              <input type="number" class="form-control" min="1" v-model="page" />
              <button type="button" class="btn btn-outline-info" @click="goNext">다음</button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-dark w-100">검색</button>
        </div>
      </div>
    </form>

    <hr class="mt-0 mb-3" />

    <div class="w-100">
      <div class="d-md-flex">

        <!-- 상위 목록 //-->


        <!-- 목록 //-->
        <div ref="contentList" id="table-wrapper" class="table-responsive mb-0 rounded-3 w-100" style="overflow: auto;">
          <div class="w-100">
            <div class="card">
              <div class="card-body">
                <!-- 리스트 //-->

                  <table class="table align-middle table-sm table-hover table-nowrap sticky-table table-striped-columns">
                    <thead class="table-dark">
                    <tr>
                      <th>
                        <a href="javascript:void(0)" @click="setSort('createdAt')">비고</a>&nbsp;
                        <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                      </th>
                      <th>{{ $t('상위') }}</th>
                      <th>{{ $t('아이디') }}</th>
                      <th>{{ $t('유형') }}</th>
                      <th>{{ $t('대상') }}</th>
                      <th>{{ $t('게임사') }}</th>
                      <th>{{ $t('게임종류') }}</th>
                      <th>{{ $t('게임형식') }}</th>
                      <th>{{ $t('게임명') }}</th>
                      <th>{{ $t('이전') }}</th>
                      <th>{{ $t('금액') }}</th>
                      <th>{{ $t('이후') }}</th>
                      <th>
                        <a href="javascript:void(0)" @click="setSort('createdAt')">{{ $t('시간') }}</a>&nbsp;
                        <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="record in list" :key="record.id">
                      <tr :class="{'border-bottom border-3': !record.message}">
                        <!-- 번호 //-->
                        <td :rowspan="record.message ? 2 : false">
                          <button type="button" class="btn btn-secondary btn-sm" @click="openDetail(record)"><span class="mdi mdi-menu"></span></button>
                        </td>
                        <!-- 상위정보 //-->
                        <td class="text-start" :rowspan="record.message ? 2 : false">

                          <router-link :to="'/partner/detail/' + record.top.id" target="_blank" v-if="record.top && record.top.id !== record.user.id && ($store.getters['auth/user'].level != 'super' && $store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.top" :show-name="true"></partner-badge></router-link>
                          <router-link :to="'/partner/detail/' + record['super'].id" target="_blank" v-if="record['super'] && record['super'].id !== record.user.id && ($store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.super" :show-name="true"></partner-badge></router-link>
                          <router-link :to="'/partner/detail/' + record.master.id" target="_blank" v-if="record.master && record.master.id !== record.user.id && ($store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.master" :show-name="true"></partner-badge></router-link>
                          <router-link :to="'/partner/detail/' + record.agent.id" target="_blank" v-if="record.agent && record.agent.id !== record.user.id && ($store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.agent" :show-name="true"></partner-badge></router-link>
                          <router-link :to="'/partner/detail/' + record.shop.id" target="_blank" v-if="record.shop && record.shop.id !== record.user.id"><partner-badge :partner="record.shop" :show-name="true"></partner-badge></router-link>

                        </td>
                        <!-- 회원정보 //-->
                        <td class="text-center">
                          <template v-if="record.user">
                            <partner-badge :partner="record.user"></partner-badge>
                            <span class="ms-1"><router-link :to="'/user/detail/' + record.user.id" target="_blank" v-if="record.user.type === 'user'">{{ record.user.username }}</router-link></span>
                            <span class="ms-1"><router-link :to="'/partner/detail/' + record.user.id" target="_blank" v-if="record.user.type === 'partner'">{{ record.user.username }}</router-link></span>
                            <!--
                            <br />
                            <span v-if="record.groupKey">{{ record.groupKey }} - </span> {{ record.siteUsername }}
                            //-->
                          </template>
                        </td>
                        <!-- 거래 형식 //-->
                        <td>
                          <badge-seamless :use-seamless="record.parent.useSeamless" v-if="record.parent && record.category === 'turn'"></badge-seamless>
                          <badge-transaction-category :record="record"></badge-transaction-category>-<badge-transaction-type :record="record"></badge-transaction-type>
                        </td>
                        <!-- 수신인 //-->
                        <td class="text-center">
                          <partner-badge :partner="record.sender"></partner-badge>
                          {{ record.sender ? record.senderUsername : '' }}
                        </td>
                        <!-- 게임사 //-->
                        <td>{{ record.vendorName }}</td>
                        <!-- 게임종류 //-->
                        <td>
                          <badge-game-category :record="record"></badge-game-category>
                        </td>
                        <!-- 게임형식 //-->
                        <td>{{ record.gameTypeName.toUpperCase() }}</td>
                        <!-- 게임명 //-->
                        <td>
                          <img :src="record.gameImage" style="width: 32px;" class="img-thumbnail" v-if="record.gameImage" />
                          {{ record.gameName }}
                        </td>
                        <!-- 이전 보유알 //-->
                        <td class="text-end">{{ record.beforeAmount.floatVal(2).formatThousands() }}</td>
                        <!-- 금액 //-->
                        <td class="text-end" :class="{'text-danger' : (record.type === 'send' || record.type === 'bet' || (record.user.type === 'partner' && record.type === 'enter'))}">{{ record.amount.floatVal(2).formatThousands() }}</td>
                        <!-- 이후 보유알 //-->
                        <td class="text-end">{{ record.afterAmount.floatVal(2).formatThousands() }}</td>
                        <!-- 생성일 //-->
                        <td class="small"><span v-b-tooltip.hover data-placement="top" :title="record.createdAt">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</span></td>
                      </tr>
                      <tr v-if="record.message" class="border-bottom border-3">
                         <td colspan="12" class="text-start"><span class="fa fa-sticky-note text-warning"></span> {{ record.message }}</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Detail ref="modal-detail" />
  </div>
</template>
